<template>
  <div class="form-group row">
    <label :for="db_field + '_field'" class="col-sm-4 col-form-label" v-if="label.length !== 0">{{ label }}</label>
    <div :class="label.length !== 0 ? 'col-sm-8' : 'col-12'">
      <v-select
          v-if="editable"
          :label="optionLabel"
          :multiple="multiple"
          :id="db_field + '_field'"
          @input="updateField(db_field, $event)"
          :options="options"
          v-model="model[field]"
          :clearable="clearable"
      ><span slot="no-options">Inga värden</span>
      </v-select>
      <div class="pt-2" v-if="!editable && !multiple">{{ model[field][optionLabel] }}</div>
      <div class="pt-2" v-if="!editable && multiple">
        <span v-for="item in model[field]" :key="item[optionLabel]">{{ item[optionLabel] }}, </span>
      </div>
    </div>
  </div>
</template>

<script>
import BojAPI from '@/api/boj';
export default {
  name: 'loader',
  props: {
    clearable: Boolean,
    multiple: Boolean,
    options: Array,
    model: Object,
    field: String,
    db_field: String,
    type: String,
    label: String,
    optionLabel: {
      type: String,
      default: 'label',
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    updateField(field, event) {
      let value = '';
      let fieldname = field;

      if (this.model[this.field] === null) {
        value = null;
      } else {
        if (this.multiple) {
          value = this.model[this.field];
        } else {
          value = this.model[this.field].id;
        }
      }

      BojAPI.updateLocalOfficeField(this.model, field, value)
          .then((response) => {
            this.$emit('updateMember', response.data.data);
            this.$notify({
              group: 'foo',
              text: this.$t('labels.changes-saved'),
              type: 'success',
              duration: 3000,
            });
            this.reRender = true;
          })
          .catch((error) => {
            this.$notify({
              group: 'foo',
              text: this.$t('labels.no-permission'),
              type: 'warning',
              duration: 3000,
            });
          });
    },
  },
};
</script>
